import React, { useState, useEffect } from 'react'
import { useTransition, animated, config } from 'react-spring'
import PropTypes from 'prop-types'

const FadeInOutVideo = ({ count }) => {
    const [index, set] = useState(0)
    const [Images, setImages] = useState([])


    useEffect(() => {
        const randomItem = () => {
            const arrayOfImages = [
                'images/airplane.jpg',
                'images/amazing.jpg',
                'images/BeachSea.jpg',
                'images/city.jpg',
                'images/jump.jpg',
                'images/rockwater.jpg',
                'images/View.jpg',
            ];

            let uniqueArray = [];
            while (uniqueArray.length < count || uniqueArray.length > arrayOfImages.length) {
                var randomItemFromArray = Math.floor(Math.random() * arrayOfImages.length);
                if (uniqueArray.indexOf(randomItemFromArray) === -1) {
                    uniqueArray = [...uniqueArray, randomItemFromArray];
                }
            }

            return (uniqueArray.map((VALUE, INDEX) => { return ({ id: INDEX, url: arrayOfImages[VALUE] }) }));


        }

        setImages(randomItem());


        let intervalInternalID = setInterval(() => set(state => (state + 1) % count), 4000)

        return function () {
            console.log("cleaning");
            clearInterval(intervalInternalID);
        }
    }, [])

    const transition = useTransition(Images[index], {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.molasses,
    })

    return transition((values, item) => (
        //<animated.div id="imageBackground" style={{ ...values, backgroundImage: `url(https://images.unsplash.com/${item.url}&auto=format&fit=crop)` }} />
        <animated.img id="imageBackground" src={item.url} style={{ ...values }}></animated.img>
    ))
}

FadeInOutVideo.defaultProps = {
    count: 4,
}

FadeInOutVideo.propTypes = {
    count: PropTypes.number,
}

export default FadeInOutVideo
