import React from 'react'
import PropTypes from 'prop-types'

const image = ({ image, key }) => {
    if (image === undefined || image === "") return "";
    return (<a key={key} href={image}><img src={image} alt="Not available."></img></a>)
};

const images = ({ arrayOfImages, hasImages }) => {
    const getImages = () => {
        if (hasImages === false) {
            return "";
        }
        return arrayOfImages.map((imageToUse, index) => {
            return (image({ image: imageToUse, key: index }));
        });
    };


    return (getImages());
}

images.propTypes = {
    arrayOfImages: PropTypes.array,
    hasImages: PropTypes.bool
};

image.propTypes = {
    image: PropTypes.string,
};

export default images