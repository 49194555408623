import React, { useState, useEffect } from 'react'
import { useTrail, a } from 'react-spring'


function AnimateText({ children, ...props }) {
    const [ShouldAnimate, setShouldAnimate] = useState(false);
    useEffect(() => {
        setShouldAnimate(true);
    }, [setShouldAnimate])
    const items = React.Children.toArray(children)
    const trail = useTrail(items.length, {
        config: { mass: 10, tension: (Math.random() * (2500 - 500) + 500), friction: 100 },
        opacity: ShouldAnimate ? 1 : 0,
        x: ShouldAnimate ? 0 : 500,
        from: { opacity: 0, x: 100 },
    })
    return (
        <div{...props}>
            {trail.map(({ x, ...rest }, index) => (
                <a.div
                    key={index}
                    style={{ ...rest, transform: x.interpolate((x) => `translateX(${x}px)`) }}>
                    {items[index]}
                </a.div>
            ))
            }
        </div >
    )
}

export default AnimateText
