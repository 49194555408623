import React from 'react';
import { NavLink } from 'react-router-dom';
import { BrowserView, MobileView } from "react-device-detect";


import 'bootstrap/dist/css/bootstrap.min.css';

//Css for some of the styling
import './CSS/bilderAnimation.css';
import './CSS/general.css';
import './CSS/index.css';
import './CSS/scrollArrow.css';
import './CSS/kontaktaMig.css';
import FadeInOut from './components/fadeInOut';
import FadeInOutVideo from './components/fadeInOutVideo';
import AnimateText from './components/AnimateText';

function Contact() {
    //useScript('/JavascriptFiles/jquery-3.6.0.min.js');
    // useScript('/JavascriptFiles/run.js');

    const navSlide = () => {
        const burger = document.querySelector('.burger');
        const nav = document.querySelector('#top-menu');
        const navLinks = document.querySelectorAll('#top-menu li')

        nav.classList.toggle('nav-active');


        navLinks.forEach((link, index) => {
            if (link.style.animation) {
                link.style.animation = '';
            }
            else {
                link.style.animation = `navLinkFade 0.5s ease forwards ${index / 5 + 0.5}s`;
            }
        });

        burger.classList.toggle('toggle');


    }
    return (
        <div className="Contact" id="root">
            <div id="wrapper">
                <nav id="nav">
                    <a className="active" href="/" style={{ textDecoration: 'none' }}>
                        <div id="textNav">
                            <img src="images/logo/white_logo.svg" alt="Not available."></img>
                            <p><b>CV</b></p>
                        </div>
                    </a>
                    <ul id="top-menu">
                        <li><NavLink to="/projects">Projects</NavLink></li>
                        <li><a href="https://coderbyte.com/profile/nicho015">Coderbyte</a></li>
                        <li><NavLink to="/experience">Experience</NavLink></li>
                        <li><a href="kontaktaoss.html">Kontakta mig</a></li>

                    </ul>

                    <div>
                        <div className="burger" onClick={navSlide}>
                            <div className="line1"></div>
                            <div className="line2"></div>
                            <div className="line3"></div>
                        </div>
                    </div>
                </nav>
            </div>
            <div className="backgroundPicture">
                <div>
                    <BrowserView renderWithFragment="true">
                        <FadeInOutVideo count={3}></FadeInOutVideo>
                    </BrowserView>
                    <MobileView renderWithFragment="true">
                        <FadeInOut count={3}></FadeInOut>
                    </MobileView>
                </div>
                <div>
                    <AnimateText className="content">
                        <div id="Combined">
                            <div id="box1"></div>
                        </div>
                        <div id="textBild" style={{ marginTop: 0 }}>
                            <h1 id="title">Contact Information</h1>
                        </div>
                    </AnimateText>
                    <a className="ca3-scroll-down-link ca3-scroll-down-arrow" id="button1" data-ca3_iconfont="ETmodules"
                        data-ca3_icon="" data-scroll href="#text"></a>
                </div>
            </div>
            <div id="container">

                <div id="middleCont">

                    <div id="textField" style={{ marginTop: "2%" }}>

                        <section>

                            <div id="text">
                                <h1><b><i>Contact Information</i></b></h1>
                                <hr></hr><br></br>

                                <h2><b>Nicholas Sjöqvist Obucina</b></h2>
                                <br></br>
                                <p>Telephone: <a href="tel:+46-076-196-2415" style={{ color: "black" }}>076 1962 415</a></p>
                                <p>Email: <a href="mailto:nicholas.obucina@hotmail.com" style={{ color: "black" }}>nicholas.obucina@hotmail.com</a></p>
                                <br></br>
                                <p>Please, do not hesitate to contact me.</p>

                            </div>

                        </section>

                    </div>

                </div>
            </div>
            <footer>
                <div className="vansterInfo"></div>
                <img src="images/logo/white_logo.svg" id="whitelogo" alt="Not available."></img>
                <div id="collection">
                    <p>Tel: <a href="tel:+46-076-196-2415" style={{ color: "white" }}>076 1962 415</a></p>
                    <p><a href="mailto:nicholas.obucina@live.se">nicholas.obucina@live.se</a></p>
                </div>
            </footer>
        </div >

    );
}

export default Contact;