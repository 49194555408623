import React from 'react';
import Item from './Item';
import PropTypes from 'prop-types'

const ListOfItems = ({ Items, onClick }) => {

    const items = () => Items.map((item) => {

        return <Item key={item['id']} name={item['attributes']['title']} onClick={() => { onClick(item['id']) }} />
    });

    return (
        <div>
            <ul>
                {items()}
            </ul>
        </div>
    );
}

ListOfItems.defaultProp = {
    Items: [],
}

ListOfItems.propTypes = {
    Items: PropTypes.array,
    checkBoxes: PropTypes.array,

}

export default ListOfItems
