import React from 'react';
import { NavLink } from 'react-router-dom';
import { BrowserView, MobileView } from "react-device-detect";


import 'bootstrap/dist/css/bootstrap.min.css';

//Css for some of the styling
import './CSS/bilderAnimation.css';
import './CSS/general.css';
import './CSS/index.css';
import './CSS/scrollArrow.css';

import FadeInOut from './components/fadeInOut';
import FadeInOutVideo from './components/fadeInOutVideo';
import AnimateText from './components/AnimateText';

function homeSite() {
    //useScript('/JavascriptFiles/jquery-3.6.0.min.js');
    // useScript('/JavascriptFiles/run.js');

    const navSlide = () => {
        const burger = document.querySelector('.burger');
        const nav = document.querySelector('#top-menu');
        const navLinks = document.querySelectorAll('#top-menu li')

        nav.classList.toggle('nav-active');


        navLinks.forEach((link, index) => {
            if (link.style.animation) {
                link.style.animation = '';
            }
            else {
                link.style.animation = `navLinkFade 0.5s ease forwards ${index / 5 + 0.5}s`;
            }
        });

        burger.classList.toggle('toggle');


    }
    return (
        <div className="homeSite" id="root">
            <div id="wrapper">
                <nav id="nav">
                    <a className="active" href="/" style={{ textDecoration: 'none' }}>
                        <div id="textNav">
                            <img src="images/logo/white_logo.svg" alt="Not available."></img>
                            <p><b>CV</b></p>
                        </div>
                    </a>
                    <ul id="top-menu">
                        <li><NavLink to="/projects">Projects</NavLink></li>
                        <li><a href="https://coderbyte.com/profile/nicho015">Coderbyte</a></li>
                        <li><NavLink to="/experience">Experience</NavLink></li>
                        <li><NavLink to="/contact">Contact Me</NavLink></li>

                    </ul>

                    <div>
                        <div className="burger" onClick={navSlide}>
                            <div className="line1"></div>
                            <div className="line2"></div>
                            <div className="line3"></div>
                        </div>
                    </div>
                </nav>
            </div>
            <div className="backgroundPicture">

                <div>
                    <BrowserView renderWithFragment="true">
                        <FadeInOutVideo count={3}></FadeInOutVideo>
                    </BrowserView>
                    <MobileView renderWithFragment="true">
                        <FadeInOut count={3}></FadeInOut>
                    </MobileView>
                </div>
                <div>
                    <AnimateText className="content">
                        <img src="images/logo/black_logo.svg" id="logo" alt="Not available."></img>
                        <div id="Combined">
                            <div id="box1"></div>
                        </div>
                        <div id="textBild">
                            <h1 id="title">Nicholas</h1>
                            <h2 id="second_title">Sjöqvist Obucina</h2>
                        </div>
                        <div id="pText">
                            <p>Programming</p>
                            <p id="nummer"><a href="tel:+46-076-196-2415" style={{ color: "black" }}>076 196 2415</a></p>
                        </div>
                    </AnimateText>
                    <a className="ca3-scroll-down-link ca3-scroll-down-arrow" id="button1" data-ca3_iconfont="ETmodules"
                        data-ca3_icon="" data-scroll href="#text"></a>
                </div>
            </div>
            <div id="container">

                <div id="text">
                    <h1><b><i>Introduction</i></b></h1>
                    <hr></hr>
                    <p>Hello and welcome to my resumé website! Here I will maintain and document the projects I have been working on throughout my time as a learning programmer. I hope it will be a pleasant read and feel free to contact me at any time!  </p>
                    <h2>So, who am I?</h2>
                    <p>My name Is Nicholas Sjöqvist Obucina, and I am a junior programmer with a flaming passion for programming. I have had various amounts of interest through my years, but programming has always remained the clear favorite. I started my journey at about 12 years old when I received my first edition of the "C++ direkt" book made by Jan Skansholm. Since then, I have used a wide range of programming languages as a fun way to create projects and solve particular problems. </p>
                    <br></br>
                    <h2>To list a few (you can read more under "Projects"): </h2>
                    <ul>
                        <NavLink to="/projects" style={{ color: "black", textDecoration: "none" }}>
                            <li>Graduation project - Functional keygen with website, login, signup (need to be admin), and code generation on the website [Reverse Engineering, .NET Assembly, C#, Website, NodeJS]</li>
                            <li>nicsjq.com - This website [HTML, CSS, Javascript and ReactJS].</li>
                            <li>Structure finder for the videogame Minecraft, available as Open Source [C#, Tesseract]</li>
                            <li>Visual hacking software for the videogame Battlefield 1942 (Game is old and not in function anymore) [C++]</li>
                            <li>Price checking website that keeps track of the prices on pricerunner and prisjakt. If a price changes, it will automatically send a email if that is the case [Python, Beautifulsoup4]</li>
                            <li>Solving Reverse engineering challenges available on the Internet [Reverse Engineering, Assembly]</li>
                            <li>Currently participating in two Java courses [Java]</li>
                            <li>Completing challanges on CoderByte.com [C++]</li>
                        </NavLink>
                    </ul>
                </div>
            </div>
            <footer>
                <div className="vansterInfo"></div>
                <img src="images/logo/white_logo.svg" id="whitelogo" alt="Not available."></img>
                <div id="collection">
                    <p><a href="tel:+46-076-196-2415" style={{ color: "white" }}>076 196 2415</a></p>
                    <p><a href="mailto:nicholas.obucina@live.se">nicholas.obucina@live.se</a></p>
                </div>
            </footer>
        </div >

    );
}

export default homeSite;